import { useMemo } from 'react';
import { ICurrentUser } from '~/services/api/auth/types';
import { UserRole } from '~/types';

const roles = [UserRole.HealthManager, UserRole.ProgramAdmin];

export const useIsActivityCounterEnabled = (currentUser: ICurrentUser) =>
  useMemo(() => roles.includes(currentUser.roleId), [currentUser]);

export default {};
