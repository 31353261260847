import { Actions, Helpers, Thunk, thunk as untypedThunk } from 'easy-peasy';
import { ISnackbarModel } from './snackbar/types';
import { IUsersModel } from './users/types';
import { IProgramsModel } from './programs/types';
import { IDashboardModel } from './dashboard/types';
import { IPatientsModel } from './patients/types';
import { IDevicesModel } from './devices/types';
import { INotesModel } from './notes/types';
import { IPatientModel } from './patient/types';
import { IActivityLogsModel } from './activityLogs/types';
import { IStatusPatientsModel } from './statusPatients/types';
import { IStatusUsersModel } from './statusUsers/types';
import { IStatusMobileUsersModel } from './statusMobileUsers/types';
import { IInsurancesModel } from './insurances/types';
import { IMedicationsModel } from './medications/types';
import { IAuditLogsModel } from './auditLogs/types';
import { IAuthModel } from './auth/types';
import { IPatientNotificationRulesModel } from './patientNotificationRules/types';
import { IMobileUsersModel } from './mobileUsers/types';
import { INotificationsModel } from './notifications/types';
import { ICarePlanIcd10CodesModel } from './carePlanIcd10Codes/types';
import { ICarePlanMedicationsModel } from './carePlanMedications/types';
import { ICarePlanHospitalizationsModel } from './carePlanHospitalizations/types';
import { ICarePlanGoalsModel } from './carePlanGoals/types';
import { ICarePlanInterventionsModel } from './carePlanInterventions/types';
import { ITableCustomizationsModel } from './tableCustomizations/types';
import { IReadingsModel } from './readings/types';
import { ITemplatesModel } from './templates/types';
import { ICarePlansModel } from './carePlans/types';

type Payload<TThunk> = TThunk extends Thunk<any, infer TPayload> ? TPayload : never;

// eslint-disable-next-line @typescript-eslint/ban-types
export const thunk = <TModel extends object, TThunk>(
  thunkBody: (
    actions: Actions<TModel>,
    payload: Payload<TThunk>,
    helpers: Helpers<TModel, IStoreModel, any>,
  ) => any,
) => untypedThunk<TModel, Payload<TThunk>, any, IStoreModel>(thunkBody);

export interface IStoreModel {
  snackbar: ISnackbarModel;
  auth: IAuthModel;
  users: IUsersModel;
  programs: IProgramsModel;
  dashboard: IDashboardModel;
  patients: IPatientsModel;
  devices: IDevicesModel;
  notes: INotesModel;
  patient: IPatientModel;
  activityLogs: IActivityLogsModel;
  statusPatients: IStatusPatientsModel;
  statusUsers: IStatusUsersModel;
  statusMobileUsers: IStatusMobileUsersModel;
  insurances: IInsurancesModel;
  medications: IMedicationsModel;
  auditLogs: IAuditLogsModel;
  patientNotificationRules: IPatientNotificationRulesModel;
  mobileUsers: IMobileUsersModel;
  notifications: INotificationsModel;
  carePlanIcd10Codes: ICarePlanIcd10CodesModel;
  carePlanMedications: ICarePlanMedicationsModel;
  carePlanHospitalizations: ICarePlanHospitalizationsModel;
  carePlanGoals: ICarePlanGoalsModel;
  carePlanInterventions: ICarePlanInterventionsModel;
  tableCustomizations: ITableCustomizationsModel;
  readings: IReadingsModel;
  templates: ITemplatesModel;
  carePlans: ICarePlansModel;
}
